.nav-bar {
  display: flex;
  font-size: 1em;
}

.nav-bar .nav-item {
  flex-grow: .1;
  padding: .5em 1em;
  margin-right: .2em;
  text-align: center;
  background-color: #eee;
  border: 1px solid #aaa;
  border-radius: 4px;
  transition: background-color 100ms linear;
}

.nav-bar .nav-item:hover {
  background-color: #bbb;
}

.nav-bar .nav-item a,
.nav-bar .nav-item a:visited {
  color: black;
}

.jumbo-card {
  margin: 40px 0;
  border: 1px solid #ddd;
  padding: 20px 10px;
  border-radius: 4px;
  background-color: #eeeeee61;
  position: relative;
}
