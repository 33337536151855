.feedback-icons {
  display: flex;
  align-items: flex-start;
}

.feedback-icon {
  flex: 1;
  text-align: center;
}

.feedback-icon img {
  width: 50%;
  cursor: pointer;
}
