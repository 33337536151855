
.row {
  display: flex;
}

.row .col {
  flex: 1;
}

.edit-node-branches .row .col {
  padding: 0 1em 0 0;
}

.edit-node-branches .field input[type=text],
.edit-node-branches .field textarea {
  width: 90%;
}

.edit-node-branches .field textarea {
  height: 5em;
}

.field select {
  font-size: 1em;
  width: 100%;
}

.edit-node-branches .header {
  font-weight: 500;
  font-size: 10pt;
}

.edit-node-branches .sub-header {
  font-weight: 300;
  font-size: 9pt;
}

.infobox-edit {
  padding: 1em;
  margin: 1em 0 3em;
  background-color: #eee;
  border-radius: .3em;
  border: .1em solid #999;
}

ul.product-list-dropdown {
  list-style: none;
  margin: 0;
  padding: 0;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

ul.product-list-dropdown li {
  cursor: pointer;
  padding: 5px 10px;
}
ul.product-list-dropdown li span.value {
  display: inline-block;
  margin: 0 .5em;
}
ul.product-list-dropdown li:hover {
  background-color: #eee;
}
