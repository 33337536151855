
.branch-button-link {
  text-decoration: none;
}

.branch-button {
  display: flex;
  background-image: linear-gradient(180deg,#f2f2f2,#e4e4e4);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.5);
  margin: 35px 0;
  border-radius: 5px;
  color: #1a1919;
  font-size: 2em;
  font-weight: 400;
  min-height: 115px;
}

.branch-button > div {
  display: block;
  float: none;
  flex: 1;
  align-self: center;
}

.branch-button .branch-icon {
  flex: 1;
}

.branch-button .branch-name-and-helptext {
  flex: 1;
  position: relative;
}

.branch-button .branch-name-and-helptext:first-of-type {
  padding-left: 30px;
}

.branch-button .branch-info {
  flex: 0.5;
  text-align: right;
  padding-right: 30px;
}

.info-icon {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: -25px;
}

.branch-name-and-info {
  position: relative;
}

.branch-button.with-icon-left .branch-icon {
  height: 115px;
  max-width: 100px;
  display: flex;
  margin-right: 20px;
  align-items: center;
  background: #fff;
  position: relative;
  padding: 0 10px;
  border-radius: 5px 0 0 5px;
}

.branch-button.with-icon-right .branch-icon {
  text-align: right;
  height: 115px;
}

.branch-helptext {
  font-size: 14px;
  font-weight: 400;
}

.branch-button.with-icon .branch-icon img {
  height: 100%;
}

.branch-button.with-icon.with-icon-right .branch-icon img {
  border-radius: 0 4px 4px 0;
}

.branch-button.with-icon.with-icon-left .branch-icon img {
  border-radius: 4px 0px 0px 4px;
  max-width: 100px;
  max-height: 100%;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 600px) {
  .branch-button  {
    font-size: 1em;
    min-height: 80px;
  }
  .branch-button.with-icon-right .branch-icon,
  .branch-button.with-icon-left .branch-icon {
    height: 80px;
  }
  .branch-helptext {
    display: none;
  }
  .branch-button .branch-name-and-helptext:first-of-type {
    padding-left: 5px;
  }

  .branch-button .branch-info {
    padding-right: 5px;
  }
}
