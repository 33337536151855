
.tree-node.open ul {
  display: block;
}

.tree-node.closed ul {
  display: none;
}

.node-header {
  font-size: 10pt;
  color: #777;

}

.node-header::before {
  content: "\201D";
}

.node-header::after {
  content: "\201D";
}



.tree-node .tree-branch {
  cursor: pointer;
  color: #337ab7;
  margin: 1em 0 0;
}

.tree-node.open .tree-branch::after {
  content: "▼";
  font-size: 8pt;
  margin: 0 1em;
}

.tree-node.closed .tree-branch::after {
  content: "▶";
  font-size: 8pt;
  margin: 0 1em;
}

.tree-node .branch-end {
  margin: 1em 0;
}

.tree-node .branch-end .name {
  color: #c7254e;
}

ul.tree-node-branches {
  margin: 0;
  list-style: none;
}

.pick-node-categories {
  border: 1px solid #ccc;
  border-radius: .3em;
  padding: .4em .8em .4em;
  background-color: #efefef;
  color: white;
}

.pick-node-category {
  float: left;
  margin: 0 1em .2em 0;
  font-size: 10pt;
  color: #000;
  cursor: pointer;
  min-width: 280px;
  position: relative;
}

.pick-node-category .tooltip {
  position: absolute;
  z-index: 1;
  background-color: #dededc;
  display: inline-block;
  top: -3em;
  right: 0;
  display: none;
  padding: .4em 1em;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
}

.pick-node-category:hover .tooltip {
  display: inline-block;
}

.pick-node-category:hover,
.pick-node-category.selected {
  color: #337ab7;
  text-decoration: underline;
}

.pick-node-category::before {
  content: "\2713";
  margin: 0 .1em;
  color: green;
  visibility: hidden;
}

.pick-node-category.selected::before {
  visibility: visible;
}

.pick-node-category:last-child {
  float: none;
  clear: both;
}

.pick-node-categories .close {
  cursor: pointer;
  text-align: right;
  color: black;
  font-size: 20pt;
}

.categories-empty {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}

.pill.category {
  float: left;
  margin: .1em;
  padding: 5px 10px;
  background: #e4e4e4;
  color: black;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.pill.pill-close-icon {
  cursor: pointer;
  margin-left: .5em;
  font-size: 11pt;
  color: #862e2e;
  display: inline-block;
  transition: color 125ms;
}

.pill.pill-close-icon:hover {
  color: #000000;
}

.node-chosen-categories .categories::after {
  display: block;
  content: " ";
  clear: both;
}

.node-chosen-categories {
  margin: 1em 0 0 2em;
  color: black;
  font-size: 10pt;
}

.mini-button {
  text-decoration: underline;
  background-color: #5396d0;
  cursor: pointer;
  display: inline-block;
  padding: .2em 1em;
  border: 1px solid #5396d0;
  margin: .2em;
  border-radius: 4px;
  color: white;
  transition: background-color 200ms linear;
}

.mini-button.mini-button-red {
  background-color: #e8545d;
  border: 1px solid #960811;
}
.mini-button.mini-button-red:hover {
  background-color: #b9000b;
}

.checkmark-before::before {
  content: "\2714";
  display: inline-block;
  padding: 0 5px;
  color: green;
  font-size: 2em;
}

.checkmark-after::after {
  content: "\2714";
  display: inline-block;
  padding: 0 5px;
  color: green;
  font-size: 2em;
}
