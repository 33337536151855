
.product {
  margin: 1em 0;
}

.product-images img {
  width: 20%;
}

.product-form {
  max-width: 600px;
}

select.select {
  width: 60%;
  font-size: 14pt;
}

.field {
  margin: 0 0 1em 0;
}

.field label {
  display: block;
  margin-bottom: .5em;
}

.field textarea {
  width: 100%;
  border-radius: 4px;
}
.field textarea,
.field input[type=text],
.field input[type=search] {
  font-size: 12pt;
  border: 1px solid #CCC;
  border-radius: 4px;
  box-sizing: border-box;
}

.field input[type=text],
.field input[type=search] {
  width: 100%;
  padding: 0.5em;
}

.actions .button {
  padding: .5em 1em;
  border: 1px solid #007f00;
  background-color: #3cb33c;
  color: white;
  font-size: 12pt;
  cursor: pointer;
  border-radius: .2em;
  transition: background-color 200ms, box-shadow 200ms;
}

.actions .button:hover {
  background-color: green;
  box-shadow: 0px 0px 8px 0px #14b714;
}
