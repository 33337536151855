.breadcrumb {
  display: flex;
}

.breadcrumb-item {
  margin-right: 1em;
}

.breadcrumb-item a:visited {
  color: black;
}

.breadcrumb-item a {
  text-decoration: underline;
  font-weight: 200;
  transition: color 200ms linear;
}

.breadcrumb-item a:hover {
  color: #98C5CB;
}

.breadcrumb-item:last-child {}

section.guide {
  max-width: 768px;
  margin: 0 auto;
}

section.guide .branch-header {
  text-align: center;
  margin-bottom: 4em;
}

section.guide .branch-header h1 {
  font-size: 2.2em;
  font-weight: 400;
}

section.guide .branch-header p {
  font-weight: 300;
}

@media screen and (max-width: 480px) {
  section.guide .branch-header h1 {
    font-size: 1.6em;
  }
}
