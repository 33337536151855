
table.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 10pt;
}

table.table thead th {
  text-align: left;
}

table.table td,
table.table th {
  padding: 1em;
  border: 1px solid #ccc;
}
