
.languages {
  display: -webkit-flex;
  display: flex;
}

.language {
  width: 0px;
  height: 2.4em;
  visibility: hidden;
  -webkit-transition: width 125ms linear;
  transition: width 125ms linear;
}

.language.active,
.language.open {
  visibility: visible;
  width: 3.8em;
}

.language.open {
  margin: 0 0.2em;
}

.language img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.feedback-icons {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

.feedback-icon {
  -webkit-flex: 1 1;
          flex: 1 1;
  text-align: center;
}

.feedback-icon img {
  width: 50%;
  cursor: pointer;
}


.back-btn button {
  padding: .5em 1em;
  border: 1px solid #ccc;
  font-size: 12pt;
  border-radius: 4px;
}

.category .product {
  margin: 2em 0;
  padding-bottom: 2em;
  border-bottom: 2px solid #CCC;
}

.category .product::after {
  display: block;
  content: " ";
  clear: both;
}

.category .product .product-texts,
.category .product .product-image {
  float: left;

}
.category .product .product-image {
  width: 35%;
  position: relative;
  max-height: 410px;
  overflow: scroll;
}
.category .product .product-texts {
  margin: 0 10% 0 0;
  width: 50%;
}

.category .product .product-texts .attribute {
  margin-bottom: 1em;
}

.category .product .product-texts .attribute .name {
  font-weight: 500;
}

.category .product .product-image img {
  width: 100%;
}


.splash-root {
  position: absolute;
  background-color: #e60000;
  width: 100%;
  height: 100%;
  font-family: "Lato";
}

.splash {
  position: relative;
  text-align: center;
  color: white;
  top: 15%;
}

.splash-button-outer {
  margin-top: 7vh;
}

.splash-button-inner {
  text-decoration: none;
  font-size: 4vh;
  background-color: white;
  color: #e70001;
  padding: 0.5em 1em;
  display: inline-block;
  border-radius: 5px;
  text-transform: uppercase;
}

.splash-info-logo {
  width: 17vh;
}

.splash-text {
  font-weight: 400;
  font-size: 3vh;
  max-width: 500px;
  margin: 7vh auto 0;
}

.splash-brand-logo {
  margin-top: 5vh;
}

.anim-logo {
  -webkit-animation-name: logo-anim;
          animation-name: logo-anim;
  -webkit-animation-duration: 6s;
          animation-duration: 6s;

}
@media screen and (max-width: 450px) {
  .splash-button-inner {
    font-size: 2.5vh;
  }
  .splash-text {
    font-weight: 400;
    font-size: 1em;
    max-width: 500px;
    margin: 10% auto 0;
  }
}

@-webkit-keyframes logo-anim {
  from {
    -webkit-transform: rotate(0deg) scale(1.5);
            transform: rotate(0deg) scale(1.5);
  }
  to {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1);
  }
}

@keyframes logo-anim {
  from {
    -webkit-transform: rotate(0deg) scale(1.5);
            transform: rotate(0deg) scale(1.5);
  }
  to {
    -webkit-transform: rotate(360deg) scale(1);
            transform: rotate(360deg) scale(1);
  }
}


.branch-button-link {
  text-decoration: none;
}

.branch-button {
  display: -webkit-flex;
  display: flex;
  background-image: -webkit-linear-gradient(top,#f2f2f2,#e4e4e4);
  background-image: linear-gradient(180deg,#f2f2f2,#e4e4e4);
  box-shadow: 0 1px 2px 0 rgba(0,0,0,.5);
  margin: 35px 0;
  border-radius: 5px;
  color: #1a1919;
  font-size: 2em;
  font-weight: 400;
  min-height: 115px;
}

.branch-button > div {
  display: block;
  float: none;
  -webkit-flex: 1 1;
          flex: 1 1;
  -webkit-align-self: center;
          align-self: center;
}

.branch-button .branch-icon {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.branch-button .branch-name-and-helptext {
  -webkit-flex: 1 1;
          flex: 1 1;
  position: relative;
}

.branch-button .branch-name-and-helptext:first-of-type {
  padding-left: 30px;
}

.branch-button .branch-info {
  -webkit-flex: 0.5 1;
          flex: 0.5 1;
  text-align: right;
  padding-right: 30px;
}

.info-icon {
  display: inline-block;
  position: absolute;
  top: 0px;
  right: -25px;
}

.branch-name-and-info {
  position: relative;
}

.branch-button.with-icon-left .branch-icon {
  height: 115px;
  max-width: 100px;
  display: -webkit-flex;
  display: flex;
  margin-right: 20px;
  -webkit-align-items: center;
          align-items: center;
  background: #fff;
  position: relative;
  padding: 0 10px;
  border-radius: 5px 0 0 5px;
}

.branch-button.with-icon-right .branch-icon {
  text-align: right;
  height: 115px;
}

.branch-helptext {
  font-size: 14px;
  font-weight: 400;
}

.branch-button.with-icon .branch-icon img {
  height: 100%;
}

.branch-button.with-icon.with-icon-right .branch-icon img {
  border-radius: 0 4px 4px 0;
}

.branch-button.with-icon.with-icon-left .branch-icon img {
  border-radius: 4px 0px 0px 4px;
  max-width: 100px;
  max-height: 100%;
  width: 100%;
  height: auto;
}
@media screen and (max-width: 600px) {
  .branch-button  {
    font-size: 1em;
    min-height: 80px;
  }
  .branch-button.with-icon-right .branch-icon,
  .branch-button.with-icon-left .branch-icon {
    height: 80px;
  }
  .branch-helptext {
    display: none;
  }
  .branch-button .branch-name-and-helptext:first-of-type {
    padding-left: 5px;
  }

  .branch-button .branch-info {
    padding-right: 5px;
  }
}

.attribute {
  margin-bottom: 1em;
}

.attribute .name {
  font-weight: 500;
}

.branch-products .product {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  margin: 1em 0;
  border-bottom: 1px solid #ccc;
  padding: 0 0 1em 0;
}

.branch-products .product .product-image {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.branch-products .product .product-meta {
  -webkit-flex: 1 1;
          flex: 1 1;
  padding: 0 0 0 2em;
}

@media screen and (max-width: 768px) {
  .branch-products .product {
    display: block;
    margin: 1em 0;
    border-bottom: 1px solid #ccc;
    padding: 0 0 1em 0;
  }

  .branch-products .product .product-image {
    -webkit-flex: unset;
            flex: unset;
  }

  .branch-products .product .product-meta {
    -webkit-flex: unset;
            flex: unset;
  }
}

.breadcrumb {
  display: -webkit-flex;
  display: flex;
}

.breadcrumb-item {
  margin-right: 1em;
}

.breadcrumb-item a:visited {
  color: black;
}

.breadcrumb-item a {
  text-decoration: underline;
  font-weight: 200;
  -webkit-transition: color 200ms linear;
  transition: color 200ms linear;
}

.breadcrumb-item a:hover {
  color: #98C5CB;
}

.breadcrumb-item:last-child {}

section.guide {
  max-width: 768px;
  margin: 0 auto;
}

section.guide .branch-header {
  text-align: center;
  margin-bottom: 4em;
}

section.guide .branch-header h1 {
  font-size: 2.2em;
  font-weight: 400;
}

section.guide .branch-header p {
  font-weight: 300;
}

@media screen and (max-width: 480px) {
  section.guide .branch-header h1 {
    font-size: 1.6em;
  }
}


table.table {
  border-collapse: collapse;
  width: 100%;
  font-size: 10pt;
}

table.table thead th {
  text-align: left;
}

table.table td,
table.table th {
  padding: 1em;
  border: 1px solid #ccc;
}


.category-products {
  margin: 1em 0;
  background-color: #e8e8e8eb;
  padding: 1em;
  border-radius: 3px;
}

.admin-category-page .number-of-products {
  border-top: 1px solid #AAA;
  border-bottom: 1px solid #AAA;
  padding: 1em 0;
  margin: 1em 0;
}

.quoted-paragraph::before {
  content: '\201C';
}

.quoted-paragraph::after {
  content: '\201D';
}

.notification {
  background-color: #3cb33c;
  color: white;
  margin: 1em 0;
  padding: .5em 1em;
  border-radius: .2em;
}


.product {
  margin: 1em 0;
}

.product-images img {
  width: 20%;
}

.product-form {
  max-width: 600px;
}

select.select {
  width: 60%;
  font-size: 14pt;
}

.field {
  margin: 0 0 1em 0;
}

.field label {
  display: block;
  margin-bottom: .5em;
}

.field textarea {
  width: 100%;
  border-radius: 4px;
}
.field textarea,
.field input[type=text],
.field input[type=search] {
  font-size: 12pt;
  border: 1px solid #CCC;
  border-radius: 4px;
  box-sizing: border-box;
}

.field input[type=text],
.field input[type=search] {
  width: 100%;
  padding: 0.5em;
}

.actions .button {
  padding: .5em 1em;
  border: 1px solid #007f00;
  background-color: #3cb33c;
  color: white;
  font-size: 12pt;
  cursor: pointer;
  border-radius: .2em;
  -webkit-transition: background-color 200ms, box-shadow 200ms;
  transition: background-color 200ms, box-shadow 200ms;
}

.actions .button:hover {
  background-color: green;
  box-shadow: 0px 0px 8px 0px #14b714;
}

.instruction-select ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 200;
}

.instruction-select.visible-true {
  display: block;
}

.instruction-select.visible-false {
  display: none;
}


.row {
  display: -webkit-flex;
  display: flex;
}

.row .col {
  -webkit-flex: 1 1;
          flex: 1 1;
}

.edit-node-branches .row .col {
  padding: 0 1em 0 0;
}

.edit-node-branches .field input[type=text],
.edit-node-branches .field textarea {
  width: 90%;
}

.edit-node-branches .field textarea {
  height: 5em;
}

.field select {
  font-size: 1em;
  width: 100%;
}

.edit-node-branches .header {
  font-weight: 500;
  font-size: 10pt;
}

.edit-node-branches .sub-header {
  font-weight: 300;
  font-size: 9pt;
}

.infobox-edit {
  padding: 1em;
  margin: 1em 0 3em;
  background-color: #eee;
  border-radius: .3em;
  border: .1em solid #999;
}

ul.product-list-dropdown {
  list-style: none;
  margin: 0;
  padding: 0;
  border-left: 1px solid #eee;
  border-right: 1px solid #eee;
}

ul.product-list-dropdown li {
  cursor: pointer;
  padding: 5px 10px;
}
ul.product-list-dropdown li span.value {
  display: inline-block;
  margin: 0 .5em;
}
ul.product-list-dropdown li:hover {
  background-color: #eee;
}


.tree-node.open ul {
  display: block;
}

.tree-node.closed ul {
  display: none;
}

.node-header {
  font-size: 10pt;
  color: #777;

}

.node-header::before {
  content: "\201D";
}

.node-header::after {
  content: "\201D";
}



.tree-node .tree-branch {
  cursor: pointer;
  color: #337ab7;
  margin: 1em 0 0;
}

.tree-node.open .tree-branch::after {
  content: "\25BC";
  font-size: 8pt;
  margin: 0 1em;
}

.tree-node.closed .tree-branch::after {
  content: "\25B6";
  font-size: 8pt;
  margin: 0 1em;
}

.tree-node .branch-end {
  margin: 1em 0;
}

.tree-node .branch-end .name {
  color: #c7254e;
}

ul.tree-node-branches {
  margin: 0;
  list-style: none;
}

.pick-node-categories {
  border: 1px solid #ccc;
  border-radius: .3em;
  padding: .4em .8em .4em;
  background-color: #efefef;
  color: white;
}

.pick-node-category {
  float: left;
  margin: 0 1em .2em 0;
  font-size: 10pt;
  color: #000;
  cursor: pointer;
  min-width: 280px;
  position: relative;
}

.pick-node-category .tooltip {
  position: absolute;
  z-index: 1;
  background-color: #dededc;
  display: inline-block;
  top: -3em;
  right: 0;
  display: none;
  padding: .4em 1em;
  color: black;
  border: 1px solid black;
  border-radius: 4px;
}

.pick-node-category:hover .tooltip {
  display: inline-block;
}

.pick-node-category:hover,
.pick-node-category.selected {
  color: #337ab7;
  text-decoration: underline;
}

.pick-node-category::before {
  content: "\2713";
  margin: 0 .1em;
  color: green;
  visibility: hidden;
}

.pick-node-category.selected::before {
  visibility: visible;
}

.pick-node-category:last-child {
  float: none;
  clear: both;
}

.pick-node-categories .close {
  cursor: pointer;
  text-align: right;
  color: black;
  font-size: 20pt;
}

.categories-empty {
  color: #000;
  cursor: pointer;
  text-decoration: underline;
}

.pill.category {
  float: left;
  margin: .1em;
  padding: 5px 10px;
  background: #e4e4e4;
  color: black;
  border-radius: 3px;
  border: 1px solid #ccc;
}

.pill.pill-close-icon {
  cursor: pointer;
  margin-left: .5em;
  font-size: 11pt;
  color: #862e2e;
  display: inline-block;
  -webkit-transition: color 125ms;
  transition: color 125ms;
}

.pill.pill-close-icon:hover {
  color: #000000;
}

.node-chosen-categories .categories::after {
  display: block;
  content: " ";
  clear: both;
}

.node-chosen-categories {
  margin: 1em 0 0 2em;
  color: black;
  font-size: 10pt;
}

.mini-button {
  text-decoration: underline;
  background-color: #5396d0;
  cursor: pointer;
  display: inline-block;
  padding: .2em 1em;
  border: 1px solid #5396d0;
  margin: .2em;
  border-radius: 4px;
  color: white;
  -webkit-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}

.mini-button.mini-button-red {
  background-color: #e8545d;
  border: 1px solid #960811;
}
.mini-button.mini-button-red:hover {
  background-color: #b9000b;
}

.checkmark-before::before {
  content: "\2714";
  display: inline-block;
  padding: 0 5px;
  color: green;
  font-size: 2em;
}

.checkmark-after::after {
  content: "\2714";
  display: inline-block;
  padding: 0 5px;
  color: green;
  font-size: 2em;
}


.steps {
  padding-left: 2%;
}

.step {
  display: -webkit-flex;
  display: flex;
}

.step .rearrange-order {
  -webkit-flex: 0 0 180px;
          flex: 0 0 180px;
}

.step .fileupload {
  -webkit-flex: 0 0 180px;
          flex: 0 0 180px;
  text-align: center;
}

.step .field {
  -webkit-flex: 0 0 400px;
          flex: 0 0 400px;
}

.step .fileupload .file-field input[type=file] {
  display: none;
}

.step .fileupload .file-field label {
  margin-top: 1.6em;
  display: inline-block;
  border: 1px solid #ccc;
  padding: .5em 1em;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 200;
  -webkit-transition: background-color 200ms linear;
  transition: background-color 200ms linear;
}

.step .fileupload .file-field label:hover {
  background-color: #98de98;
}

.rearrange-order {
  padding-top: 1.5em;
}

.rearrange-order .ikon {
  float: left;
  margin-right: 1em;
  border-radius: 50%;
  padding: 7px 6px 3px;
  background-color: #4aa94a;
  cursor: pointer;
}

.rearrange-order .ikon.red {
  background-color: #d44e4e;
}

.rearrange-order .clear {
  float: none;
  clear: both;
}


.product-import .error {
  color: white;
  border: 1px solid red;
  padding: 0.5em 1em;
  background-color: #e50b18;
  font-weight: 600;
  border-radius: 5px;
  text-decoration: underline;
}

.product-import .varenummer {
  display: inline-block;
  margin-right: 1em;
  padding: 0.2em 0.5em;
  background-color: green;
  margin-bottom: 1em;
  color: white;
  border-radius: 3px;
  font-size: 10pt;
}

.nav-bar {
  display: -webkit-flex;
  display: flex;
  font-size: 1em;
}

.nav-bar .nav-item {
  -webkit-flex-grow: .1;
          flex-grow: .1;
  padding: .5em 1em;
  margin-right: .2em;
  text-align: center;
  background-color: #eee;
  border: 1px solid #aaa;
  border-radius: 4px;
  -webkit-transition: background-color 100ms linear;
  transition: background-color 100ms linear;
}

.nav-bar .nav-item:hover {
  background-color: #bbb;
}

.nav-bar .nav-item a,
.nav-bar .nav-item a:visited {
  color: black;
}

.jumbo-card {
  margin: 40px 0;
  border: 1px solid #ddd;
  padding: 20px 10px;
  border-radius: 4px;
  background-color: #eeeeee61;
  position: relative;
}

section.instruction {
  max-width: 768px;
  margin: 0 auto;
}


body, html {
  font-family: 'Roboto', 'Lato', 'Open Sans', sans-serif;
  background-color: white;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
}

header {
  background-color: #e60000;
  color: white;
}

header, section {
  padding: 20px;
}

section {
  margin-bottom: 5em;
}

footer {
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%;
}

