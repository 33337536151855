
.languages {
  display: flex;
}

.language {
  width: 0px;
  height: 2.4em;
  visibility: hidden;
  transition: width 125ms linear;
}

.language.active,
.language.open {
  visibility: visible;
  width: 3.8em;
}

.language.open {
  margin: 0 0.2em;
}

.language img {
  width: 100%;
  height: 100%;
  cursor: pointer;
}
