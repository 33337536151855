
.back-btn button {
  padding: .5em 1em;
  border: 1px solid #ccc;
  font-size: 12pt;
  border-radius: 4px;
}

.category .product {
  margin: 2em 0;
  padding-bottom: 2em;
  border-bottom: 2px solid #CCC;
}

.category .product::after {
  display: block;
  content: " ";
  clear: both;
}

.category .product .product-texts,
.category .product .product-image {
  float: left;

}
.category .product .product-image {
  width: 35%;
  position: relative;
  max-height: 410px;
  overflow: scroll;
}
.category .product .product-texts {
  margin: 0 10% 0 0;
  width: 50%;
}

.category .product .product-texts .attribute {
  margin-bottom: 1em;
}

.category .product .product-texts .attribute .name {
  font-weight: 500;
}

.category .product .product-image img {
  width: 100%;
}
