
.product-import .error {
  color: white;
  border: 1px solid red;
  padding: 0.5em 1em;
  background-color: #e50b18;
  font-weight: 600;
  border-radius: 5px;
  text-decoration: underline;
}

.product-import .varenummer {
  display: inline-block;
  margin-right: 1em;
  padding: 0.2em 0.5em;
  background-color: green;
  margin-bottom: 1em;
  color: white;
  border-radius: 3px;
  font-size: 10pt;
}
