
body, html {
  font-family: 'Roboto', 'Lato', 'Open Sans', sans-serif;
  background-color: white;
  -webkit-font-smoothing: antialiased;
}

body {
  margin: 0;
  padding: 0;
}

header {
  background-color: #e60000;
  color: white;
}

header, section {
  padding: 20px;
}

section {
  margin-bottom: 5em;
}

footer {
  background-color: #333;
  position: fixed;
  bottom: 0;
  width: 100%;
}
