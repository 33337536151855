
.steps {
  padding-left: 2%;
}

.step {
  display: flex;
}

.step .rearrange-order {
  flex: 0 0 180px;
}

.step .fileupload {
  flex: 0 0 180px;
  text-align: center;
}

.step .field {
  flex: 0 0 400px;
}

.step .fileupload .file-field input[type=file] {
  display: none;
}

.step .fileupload .file-field label {
  margin-top: 1.6em;
  display: inline-block;
  border: 1px solid #ccc;
  padding: .5em 1em;
  cursor: pointer;
  border-radius: 3px;
  font-weight: 200;
  transition: background-color 200ms linear;
}

.step .fileupload .file-field label:hover {
  background-color: #98de98;
}

.rearrange-order {
  padding-top: 1.5em;
}

.rearrange-order .ikon {
  float: left;
  margin-right: 1em;
  border-radius: 50%;
  padding: 7px 6px 3px;
  background-color: #4aa94a;
  cursor: pointer;
}

.rearrange-order .ikon.red {
  background-color: #d44e4e;
}

.rearrange-order .clear {
  float: none;
  clear: both;
}
