
.category-products {
  margin: 1em 0;
  background-color: #e8e8e8eb;
  padding: 1em;
  border-radius: 3px;
}

.admin-category-page .number-of-products {
  border-top: 1px solid #AAA;
  border-bottom: 1px solid #AAA;
  padding: 1em 0;
  margin: 1em 0;
}

.quoted-paragraph::before {
  content: '\201C';
}

.quoted-paragraph::after {
  content: '\201D';
}

.notification {
  background-color: #3cb33c;
  color: white;
  margin: 1em 0;
  padding: .5em 1em;
  border-radius: .2em;
}
