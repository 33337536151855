.instruction-select ul {
  margin: 0;
  padding: 0;
  list-style: none;
  font-weight: 200;
}

.instruction-select.visible-true {
  display: block;
}

.instruction-select.visible-false {
  display: none;
}
