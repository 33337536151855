.attribute {
  margin-bottom: 1em;
}

.attribute .name {
  font-weight: 500;
}

.branch-products .product {
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 1em 0;
  border-bottom: 1px solid #ccc;
  padding: 0 0 1em 0;
}

.branch-products .product .product-image {
  flex: 1;
}

.branch-products .product .product-meta {
  flex: 1;
  padding: 0 0 0 2em;
}

@media screen and (max-width: 768px) {
  .branch-products .product {
    display: block;
    margin: 1em 0;
    border-bottom: 1px solid #ccc;
    padding: 0 0 1em 0;
  }

  .branch-products .product .product-image {
    flex: unset;
  }

  .branch-products .product .product-meta {
    flex: unset;
  }
}
