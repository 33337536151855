
.splash-root {
  position: absolute;
  background-color: #e60000;
  width: 100%;
  height: 100%;
  font-family: "Lato";
}

.splash {
  position: relative;
  text-align: center;
  color: white;
  top: 15%;
}

.splash-button-outer {
  margin-top: 7vh;
}

.splash-button-inner {
  text-decoration: none;
  font-size: 4vh;
  background-color: white;
  color: #e70001;
  padding: 0.5em 1em;
  display: inline-block;
  border-radius: 5px;
  text-transform: uppercase;
}

.splash-info-logo {
  width: 17vh;
}

.splash-text {
  font-weight: 400;
  font-size: 3vh;
  max-width: 500px;
  margin: 7vh auto 0;
}

.splash-brand-logo {
  margin-top: 5vh;
}

.anim-logo {
  animation-name: logo-anim;
  animation-duration: 6s;

}
@media screen and (max-width: 450px) {
  .splash-button-inner {
    font-size: 2.5vh;
  }
  .splash-text {
    font-weight: 400;
    font-size: 1em;
    max-width: 500px;
    margin: 10% auto 0;
  }
}

@keyframes logo-anim {
  from {
    transform: rotate(0deg) scale(1.5);
  }
  to {
    transform: rotate(360deg) scale(1);
  }
}
